import { useEffect, useRef, useState, useMemo, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { throttle } from 'lodash';
import ReactTooltip from 'react-tooltip';
import { Box, Button, Flex, HStack, Image, Input } from '@chakra-ui/react';
import Captions from './Captions';
// import MicComponent from './MicComponent';
import { stopTalking } from '../utils/textToSpeech';
import { SVG } from './icons/icons';
import {
  sendTextMessage,
  mute,
  stopSpeaking,
  setShowTranscript,
  addConversationResult,
} from '../store/reducers/digitalAssistantReducer';
import mic from '../img/mic-digital.svg';
import micFill from '../img/mic-digital-fill.svg';
import breakpoints from '../utils/breakpoints';
import { AppDispatch, RootState } from '../store/store';
import chat from '../img/chat.svg';
import TextConstants from '../constants/TextConstants';
import CustomMic from "../components/common/Mic/index"
import { updateWellnessData } from '../store/reducers/authenticationReducer';

const volumeMeterHeight = 24;
const volumeMeterMultiplier = 1.2;
const smallHeight = volumeMeterHeight;
const largeHeight = volumeMeterHeight * volumeMeterMultiplier;

const Container = styled.div`
  display: ${({ connected }) => (connected ? 'flex' : 'none')};
  flex-direction: column;
  align-items: center;

  .form-control {
    opacity: 0.8;
    &:focus {
      opacity: 1;
    }
  }
  .badge {
    font-weight: normal;
    color: #000;
    border-radius: 10px;
    padding: 13px 10px 13px 20px;
  }
  .utterance-processing {
    opacity: 0.7;
    font-style: italic;
  }
  .input-display {
    transition: bottom 0.3s, opacity 0.3s;
    height: auto;
    display: flex;
    font-size: 20px;
  }
  .hide-input {
    position: relative;
    bottom: -2.5rem;
    opacity: 0;
  }
  .show-input {
    position: relative;
    bottom: 0rem;
    opacity: ${({ userSpeaking }) => (userSpeaking ? 0.7 : 1)};
  }
  .speaking-status {
    width: 47px;
    @media (min-width: ${breakpoints.md}px) {
      min-width: 56px;
    }
  }

  .volume-display {
    display: flex;
    justify-content: center;
    position: relative;
    background: #475467;
    opacity: 0.7;
    border-radius: 25px;
    margin-left: 20px;
    width: ${({ videoWidth }) => (videoWidth <= breakpoints.md ? 60 : 110)}px;
    height: ${({ videoWidth }) => (videoWidth <= breakpoints.md ? 30 : 40)}px;
    .meter-component {
      /* don't use media queries for this since we need to write the value
      in the body of the component */
      height: ${({ videoWidth }) =>
        videoWidth >= breakpoints.md ? largeHeight : smallHeight}px;
      background-size: cover;
      background-position: center;
      background-repeat: no-repeat;
      width: ${({ videoWidth }) => (videoWidth <= breakpoints.md ? 40 : 70)}px;
      position: absolute;
    }
    .meter-component-1 {
      background-image: url(${mic});
      z-index: 10;
      position: relative;
    }
    .meter-component-2 {
      background-image: url(${micFill});
      z-index: 20;
      top: 50%;
      transform: translateY(-50%);
    }
  }

  .mic-component-container {
    position: relative;
    margin-left: -50px;
    width: unset;
  }

  .mic-component-container button {
    border-width: 0;
    z-index: 99999;
  }
`;

const Controls = (): JSX.Element => {
  const [isPersonaMuted, mutePersona] = useState(false);

  const cachedTextConstants = useMemo(() => {
    // Perform any expensive calculations or lookups here if needed
    return TextConstants;
  }, []); 

  const isTranscriptOpen = useSelector(
    (state: RootState) => state.digitalAssistant.showTranscript
  );
  const transcript = useSelector((state: RootState) => state.digitalAssistant.transcript);
  const isMuted = useSelector((state: RootState) => state.digitalAssistant.isMuted);
  const typingOnly = useSelector((state: RootState) => state.digitalAssistant.typingOnly);
  const userSpeaking = useSelector((state: RootState) => state.digitalAssistant.userSpeaking);
  const connected = useSelector((state: RootState) => state.digitalAssistant.connected);
  const videoWidth = useSelector((state: RootState) => state.digitalAssistant.videoWidth);
  const _shouldSendToneAnalyzerContext = useSelector((state: RootState) => state.digitalAssistant.shouldSendToneAnalyzerContext);
  const intermediateUserUtterance = useSelector(
    (state: RootState) => state.digitalAssistant.intermediateUserUtterance
  );
  const lastUserUtterance = useSelector(
    (state: RootState) => state.digitalAssistant.lastUserUtterance
  );
  const showVirtualMirror = useSelector(
    (state: RootState) => state.vm.showVirtualMirror
  );

  const assistantId = useSelector(
    (state: RootState) => state.digitalAssistant.assistantSessionId
  );

  const userData = useSelector(
    (state: RootState) => state.authenticationState?.userData
  );


  const dispatch = useDispatch<AppDispatch>();
  const [inputValue, setInputValue] = useState('');
  const [inputFocused, setInputFocused] = useState(false);
  const [hideInputDisplay, setHideInputDisplay] = useState(true);
  const timeout = useRef<NodeJS.Timeout>();

  const handleMuteToggle = () => {
  };

  const handleInput = (e) => setInputValue(e.target.value);
  const handleFocus = () => {
    setInputFocused(true);
  };
  const handleBlur = () => setInputFocused(false);

  const throttledStopTalking = useCallback(
    throttle(() => {console.log("stop talking"); stopTalking()}, 1000),
    []
  );
  const handleMessageSubmit = async (e = null, outerMessage = null) => {    e && e.preventDefault();

    dispatch(stopSpeaking());
    throttledStopTalking();
    const messageText = (outerMessage !== null)
      ? outerMessage
      : inputValue;
    if(!_shouldSendToneAnalyzerContext)
      dispatch(sendTextMessage({ text: messageText }));
    else{
      dispatch(
        addConversationResult({
          source: TextConstants.CONVERSATION_RESULT_SOURCE.USER,
          text: messageText,
        })
      );
    }

    if(userData && !userData.wellness_status){
      dispatch(updateWellnessData({ text: messageText }));
    }

    setInputValue('');
  };

  if (userSpeaking === true && inputValue !== '' && inputFocused === false) {
    setInputValue('');
  }

  useEffect(() => {
    if (userSpeaking === true || lastUserUtterance.length > 0)
      setHideInputDisplay(false);
    const createTimeout = () =>
      setTimeout(() => {
        if (userSpeaking === false) setHideInputDisplay(true);
        else createTimeout();
      }, 3000);
    timeout.current = createTimeout();
    return () => clearTimeout(timeout.current);
  }, [userSpeaking, lastUserUtterance, isMuted]);

  const toggleMicrophone = () => {
    dispatch(mute(!isMuted));
  };

  useEffect(() => {
    ReactTooltip.rebuild();
  }, []);

  // clear placeholder text on reconnect, sometimes the state updates won't propagate
  const placeholder =
    intermediateUserUtterance === '' ? '' : intermediateUserUtterance;

  const feedbackDisplay = (
    <div className="justify-content-end col-md-10 d-flex mb-2 w-100">
      <span
        className={`badge bg-light input-display
              ${userSpeaking ? 'utterance-processing' : ''}
              ${
                (transcript.length === 0 && intermediateUserUtterance === '') ||
                hideInputDisplay
                  ? 'hide-input'
                  : 'show-input'
              }
              `}
      >
        <div className="text-wrap text-start input-display">
          {userSpeaking
             ? cachedTextConstants.CAPTIONS.USER_SPEACH_FEEDBACK.TITLE.USER_SPEAKING
             : cachedTextConstants.CAPTIONS.USER_SPEACH_FEEDBACK.TITLE
                .USER_FINISHED_SPEAKING}
          {placeholder || lastUserUtterance}
          {userSpeaking ? (
            <div>
              <div
                className="spinner-border ms-2 d-md-block d-none"
                role="status"
              >
                <span className="visually-hidden">
                  {
                    cachedTextConstants.CAPTIONS.USER_SPEACH_FEEDBACK
                    .LOADING_PLACEHOLDER
                  }
                </span>
              </div>
              <div
                className="spinner-border spinner-border-sm ms-1 d-block d-md-none"
                role="status"
              >
                <span className="visually-hidden">
                  {
                    cachedTextConstants.CAPTIONS.USER_SPEACH_FEEDBACK
                    .LOADING_PLACEHOLDER
                  }
                </span>
              </div>
            </div>
          ) : null}
        </div>
      </span>
    </div>
  );

  const onRecognizeSpeech = (text: string) => {
    setInputValue(text);
    handleMessageSubmit(null, text);
  };

  // const [isSendButtonDisabledBySpeaking, setIsSendButtonDisabledBySpeaking] = useState(false);
  const isDigitalAssistantSpeaking = useSelector((state: RootState) => state.digitalAssistant.isDigitalAssistantSpeaking);

  // useEffect(() => {
  //   setIsSendButtonDisabledBySpeaking(isDigitalAssistantSpeaking);
  // }, [isDigitalAssistantSpeaking]);

  const controlsBar = () => (
    <HStack mr="20px">
      <Input
        mr="5px"
        type="text"
        placeholder="Message"
        bgColor="#fff"
        value={inputValue}
        onChange={handleInput}
        onFocus={handleFocus}
        onBlur={handleBlur}
        aria-label="Keyboard input"
        style={{ paddingRight: '45px' }}
      />
            <CustomMic handleMessageSubmit={handleMessageSubmit}/>
      {/* <MicComponent
        onRecognizeSpeech={onRecognizeSpeech}
      /> */}
      <Button
        type="submit"
        // disabled={isSendButtonDisabledBySpeaking || !inputValue}
        variant="circle-icon"
        size="md"
        ml="14px !important"
        onClick={handleMessageSubmit}
      >
        <SVG id="send" />
      </Button>
    </HStack>
  );

  return (
    <Container
      connected={connected}
      userSpeaking={userSpeaking}
      videoWidth={videoWidth}
    >
      <Box
        className="captions-feedback-wrapper"
        position="absolute"
        bottom="10vh"
        left="30%"
        minW="35%"
      >
        <Box className="captions-wrapper" display="none" w="100%">
          <Captions />
        </Box>
        <Box
          display={
            'none'
            //   {
            //   base: 'none',
            //   xl: hideInputDisplay ? 'none' : 'flex',
            // }
          }
          className="feedback-wrapper"
          w="100%"
        >
          {feedbackDisplay}
        </Box>
      </Box>

      <div className="mb-3 d-flex  align-items-center flex-row justify-content-center">
        <form onSubmit={handleMessageSubmit} className="col ">
          <Flex justify="center">
            {controlsBar()}
          </Flex>
        </form>
      </div>
    </Container>
  );
};

export default Controls;
