import { callWatson, updateWatsonContextVariables } from '../global-components/RequestFactory';
import { CardType, DPDialogType } from '../global-components/GlobalTypes';

export const getContentCardsFromContext = (context: any) => {
    let contextMethodToUse: 'variables' | 'actionAndVariables' = 'variables';

    let actionAndVariablesContext: {
        'public-action': {
        data: any;
        component: string;
        };
    } | null = null;

    if (context && context['public-variables']) {
        if (context['public-variables'].isActionRequired && context['public-action']) {
        const publicAction = {
            data: context['public-action'],
            component: context['public-variables'].component,
        };
        actionAndVariablesContext = { 'public-action': publicAction };
        contextMethodToUse = 'actionAndVariables';
        }

        if (!context['public-variables'].isActionRequired) {
        contextMethodToUse = 'variables';
        }
    }

    const getContext = (cardKey?: string) => {
        if (contextMethodToUse === 'variables') {
        if (cardKey) {
            return context[cardKey];
        }
        return context;
        }
        if (contextMethodToUse === 'actionAndVariables') {
        if (cardKey) {
            return actionAndVariablesContext[cardKey];
        }
        return actionAndVariablesContext;
        }
        return null;
    };

    // filter out $cardName.original, we just want values for $cardName
    const relevantKeys = Object.keys(getContext()).filter((k) => /\.original/gm.test(k) === false);

    let contentCards = {};
    const activeCards: CardType[] = [];
    relevantKeys.forEach((k) => {
        const cardKey1 = k.match(/public-(.*)/m);
        const cardKey = cardKey1[1];
        try {
        //  JSON.parse(context[k]) in case of DialogFlow
        contentCards[cardKey] = getContext(k);
        activeCards.push(contentCards[cardKey]);
        } catch {
        console.error(`invalid JSON in content card payload for ${k}!`);
        }
    });

    let cardInput;

    if (activeCards.length > 1) {
        cardInput = activeCards[1];
    } else if (activeCards.length === 1) {
        cardInput = activeCards[0];
    }

    return {
        contentCards,
        activeCards,
        cardInput,
    }
};
  
export const sendChatBotRequest = async (apiToken: string, text: string, sessionId: any, dialogType: DPDialogType, contextVariables: object) => {
    const watsonResponse = await callWatson(apiToken, text, dialogType, sessionId, contextVariables);
    const textReponse = watsonResponse.body.output.text;

    return {
        textReponse,
        context: watsonResponse.body.variables,
        sessionId: watsonResponse.sessionId,
    };
};

export const sendUpdateContextRequest = async (sessionId: any, dialogType: DPDialogType, contextVariable: object) => {
    const watsonResponse = await updateWatsonContextVariables(dialogType, sessionId, contextVariable);
    console.log("watsonResponse ",watsonResponse)
    

    return watsonResponse;
};
